import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FetchApiService {
  private _headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private _httpClient: HttpClient) {}

  get(url: string, params: any, isDownload?: boolean): Observable<any> {
    let newUrl: string;

    if (params) {
      newUrl = `${url}?`;
    } else {
      newUrl = url;
    }

    for (const param in params) {
      if (newUrl.charAt(newUrl.length - 1) === '?') {
        newUrl = `${newUrl}${param}=${params[param]}`;
      } else {
        if (
          typeof params[param] === 'object' &&
          !Array.isArray(params[param])
        ) {
          for (const secondParam in params[param]) {
            newUrl = `${newUrl}&${param}.${secondParam}=${params[param][secondParam]}`;
          }
        } else {
          newUrl = `${newUrl}&${param}=${params[param]}`;
        }
      }
    }
    if (isDownload) {
      return this._httpClient.get(newUrl, {
        headers: this._headers,
        responseType: 'blob',
      });
    }

    return this._httpClient.get(newUrl, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response: any) => response,
        error: (error) => console.error(error),
      })
    );
  }

  getById(url: string, id: number): Observable<any> {
    const newUrl: string = `${url}/${id}`;

    return this._httpClient.get(newUrl, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response: any) => response,
        error: (error) => console.error(error),
      })
    );
  }

  post(
    url: string,
    payload: any,
    params?: any,
    isDownload?: boolean
  ): Observable<any> {
    let newUrl = params ? `${url}?` : url;

    if (params) {
      for (const param in params) {
        if (newUrl.charAt(newUrl.length - 1) === '?') {
          newUrl = `${newUrl}${param}=${params[param]}`;
        } else {
          newUrl = `${newUrl}&${param}=${params[param]}`;
        }
      }
    }
    if (isDownload) {
      return this._httpClient.post(newUrl, payload, {
        headers: this._headers,
        responseType: 'blob',
      });
    }
    return this._httpClient
      .post(newUrl, payload, { headers: this._headers })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response: any) => response,
          error: (error) => console.error(error),
        })
      );
  }

  put(url: string, payload: any): Observable<any> {
    const newUrl: string = url;

    return this._httpClient
      .put(newUrl, payload, { headers: this._headers })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response: any) => response,
          error: (error) => console.error(error),
        })
      );
  }
  putWithIdInBody(url: string, payload: any): Observable<any> {
    return this._httpClient.put(url, payload, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response: any) => response,
        error: (error) => console.error(error),
      })
    );
  }
  delete(url: string, id: number): Observable<any> {
    const newUrl: string = `${url}/${id}`;

    return this._httpClient.delete(newUrl, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response: any) => response,
        error: (error) => console.error(error),
      })
    );
  }
  deleteWithIdInBody(url: string, id: number): Observable<any> {
    return this._httpClient
      .delete(url, { headers: this._headers, body: { id } })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response: any) => response,
          error: (error) => console.error(error),
        })
      );
  }
}
